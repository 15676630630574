import {
  json,
  type MetaFunction,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  redirect,
  type LinksFunction,
} from "@remix-run/node"
import { Public } from "~/utils/remix"
import { Link, useActionData, useLoaderData, useSearchParams } from "@remix-run/react"
import { commitUserSession, createUserSession, getUserSession, login } from "~/utils/session.server"
import { withZod } from "@remix-validated-form/with-zod"
import { z } from "zod"
import { ValidatedForm, useField, validationError } from "remix-validated-form"
import { FormInput, SubmitButton } from "~/components/form-fields"
import { Button } from "~/components/ui/button"
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert"
import { ExclamationTriangleIcon } from "@radix-ui/react-icons"
import { Label } from "~/components/ui/label"
import { Input } from "~/components/ui/input"
import Typography from "~/components/typography"
import gtag from "~/utils/trackers/gtag"
import { useEffect } from "react"
import { TrackEvent, EventType } from "~/models/events.server"

export const meta: MetaFunction = () => {
  return [
    { title: "MakeLoveNotPorn - sign in to enjoy real world sex videos" },
    { property: "og:title", content: "MakeLoveNotPorn - sign in to enjoy real world sex videos" },
    {
      name: "description",
      content:
        "New lovemaking videos are shared every day! Sign in to see which couples shared their home made, real sex, not porn videos - all confirmed consensual and ethical by our curators.",
    },
  ]
}

const validator = withZod(
  z.object({
    UserName: z.string().min(1, { message: "Username or Email is required" }),
    Password: z.string().min(1, { message: "Password is required" }),
    redirectTo: z.string().optional(),
  })
)

export const action = Public(async ({ context, request }: ActionFunctionArgs) => {
  const result = await validator.validate(await request.formData())
  if (result.error) {
    context.logger.warn(`Login validation errors`, { form: result.data, error: result.error })
    return validationError(result.error)
  }

  const { UserName, Password, redirectTo } = result.data

  const { user, errors } = await login(context, UserName, Password)
  if (errors) {
    context.logger.warn(`Login failed for ${UserName}`, { errors })
    return json(errors, { status: errors.status })
  }

  context.user = user
  await TrackEvent(context, EventType.Login)

  const session = await createUserSession(user.UserID.toString())

  if (user.WelcomeFlow === 1) {
    await context.db.users.update({
      where: { UserID: user.UserID },
      data: { WelcomeFlow: 0 },
    })
  }

  return redirect(user.WelcomeFlow === 1 ? "/getting-started" : redirectTo || "/videos", {
    headers: {
      "Set-Cookie": await commitUserSession(session),
    },
  })
})

export const loader = Public(async ({ request, context }: LoaderFunctionArgs) => {
  if (context.user) {
    throw redirect("/videos")
  }

  const session = await getUserSession(request)
  return json(
    {
      success: session.get("success") || null,
      error: session.get("error") || null,
    },
    { headers: { "Set-Cookie": await commitUserSession(session) } }
  )
})

export const links: LinksFunction = () => [
  {
    rel: "canonical",
    href: "https://makelovenotporn.tv/login",
  },
]

export default function Login() {
  const data = useActionData<typeof action>()
  const loaderData = useLoaderData<typeof loader>()
  const [searchParams] = useSearchParams()
  const { error, getInputProps } = useField("Password", { formId: "login" })

  useEffect(() => {
    if (loaderData?.success) {
      gtag.event({ action: "signup" })
    }
  }, [])

  return (
    <div className="container flex h-screen flex-1 flex-col justify-center items-center py-12 lg:px-8">
      <Link
        to={`/register${searchParams.get("redirectTo") ? `?redirectTo=${searchParams.get("redirectTo")}` : ""}`}
        className="absolute right-4 top-4 md:right-8 md:top-8">
        <Button>Create an Account</Button>
      </Link>
      <div className="max-w-[400px] space-y-6">
        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-xl font-semibold tracking-tight">Delightful to have you with us again!</h1>
          <p className="text-sm text-muted-foreground">Enter your information below to access your account</p>
        </div>
        {data?.message && (
          <>
            {data.message == "unconfirmedUser" ?
              <Alert variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Your email hasn't yet been confirmed.</AlertTitle>
                <AlertDescription>
                  Please check your email.{" "}
                  <Link to="/resend-confirmation" className="underline">
                    Resend confirmation email
                  </Link>
                  <br />
                  Need help? Contact{" "}
                  <b>
                    <Link to="mailto:support@mlnp.tv" className="underline">
                      support@mlnp.tv
                    </Link>
                  </b>
                  .
                </AlertDescription>
              </Alert>
            : <>
                {data.message == "legacyUnconfirmedUser" ?
                  <Alert variant="destructive">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <AlertTitle>Your email hasn't yet been confirmed.</AlertTitle>
                    <AlertDescription>
                      We've just resent your confirmation email. Please check your email or{" "}
                      <Link to="/resend-confirmation" className="underline">
                        request a new confirmation email
                      </Link>
                      <br />
                      Need help? Contact{" "}
                      <b>
                        <Link to="mailto:support@mlnp.tv" className="underline">
                          support@mlnp.tv
                        </Link>
                      </b>
                      .
                    </AlertDescription>
                  </Alert>
                : <Alert variant="destructive">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <AlertTitle>Invalid Credentials</AlertTitle>
                    <AlertDescription>
                      {data.message}
                      <br />
                      Need help? Contact{" "}
                      <b>
                        <Link to="mailto:support@mlnp.tv" className="underline">
                          support@mlnp.tv
                        </Link>
                      </b>
                      .
                    </AlertDescription>
                  </Alert>
                }
              </>
            }
          </>
        )}
        {loaderData?.success && (
          <Alert variant="success">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>{loaderData.success.title}</AlertTitle>
            <AlertDescription>{loaderData.success.message}</AlertDescription>
          </Alert>
        )}
        {loaderData?.error && (
          <Alert variant="destructive">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>{loaderData.error.title}</AlertTitle>
            <AlertDescription>
              {loaderData.error.message}
              <br />
              Need help? Contact{" "}
              <b>
                <Link to="mailto:support@mlnp.tv" className="underline">
                  support@mlnp.tv
                </Link>
              </b>
              .
            </AlertDescription>
          </Alert>
        )}
        <ValidatedForm id="login" validator={validator} method="post">
          <input
            type="hidden"
            name="redirectTo"
            value={searchParams.get("redirectTo") ? searchParams.get("redirectTo")! : undefined}
          />
          <div className="grid gap-4">
            <FormInput type="text" name="UserName" label="Username or Email Address" />

            <div className="space-y-2 mb-2">
              <div className="flex justify-between items-end">
                <Label className="mx-1" htmlFor="Password">
                  Password
                </Label>
                <Typography className="leading-3">
                  <Link to="/forgot-password" className="text-xs">
                    Forgot Password?
                  </Link>
                </Typography>
              </div>
              <div className="relative">
                <Input type="password" {...getInputProps({ id: "Password" })} />
              </div>
              {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
            </div>
            <SubmitButton content="Sign in" loading="Signing in..." />
            <Typography className="block text-center">
              New to us?{" "}
              <Link to="/register" className="text-center text-sm ">
                Create an Account
              </Link>
            </Typography>
          </div>
        </ValidatedForm>
      </div>
    </div>
  )
}
